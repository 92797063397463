<template>
  <div>
    <Form :isNewConfirmation="false" />
  </div>
</template>

<script>
import Form from '../shared/Form.vue'

export default {
  components: {
    Form,
  }
}
</script>